import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import PersonalLoanImage from "../../../assets/images/blogs/personal-loan.jpg";
import Footer from "../../layouts/footer";
import Header from "../../layouts/header";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { blogPosts } from "./blogData";
const HeroSection = styled.header`
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 2;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${(props) => props.backgroundImage});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 1;
  }
  .container {
    z-index: 3;
  }
`;
const HeroTitle = styled.h1`
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
  z-index: 3;

  @media (min-width: 768px) {
    font-size: 3rem;
  }
`;

const HeroSubtitle = styled.p`
  color: #fff;
  font-size: 1rem;
  z-index: 3;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;
const StyledDiv = styled.div`
  &.section {
    margin: 50px 0;
  }
  .main-container {
    padding: 0px 45px;
  }
  .sidebar {
    border: 1px solid transparent;
    min-height: 140px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 10px #d1d1d1;
    border-radius: 5px;
    padding: 10px;
    gap: 15px;
  }
  .sidebar.table-of-content {
    position: sticky;
    top: 90px;
  }
  .category h2,
  h2 {
    color: #1a73e8; /* Adjust color as needed */
  }
  .category-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .card-label {
    height: 45px;
    border: 1px solid #10bd62;
    display: flex;
    gap: 5px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    background: linear-gradient(45deg, #000000d9, #04b35f);
    color: #fff;
    padding: 0 25px;
    font-size: 18px;
  }
  .card-label.active {
    background: #1a73e8;
  }
  .actegory-label {
    display: flex;
    flex-direction: row;
    gap: 5px;
    font-size: 13px;
    color: #d1d1d1;
    align-items: center;
  }
  .divider {
    width: 7px;
    height: 0px;
    border: 1px solid #d1d1d1;
  }
  .table-of-content-cards {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .content-label-text {
    text-decoration: underline;
    font-size: 18px;
    color: #04b05e;
  }
  .content-label-text a {
    color: #04b05e;
  }
  .bullet {
    height: 5px;
    width: 5px;
    background: #d1d1d1;
    border-radius: 50px;
  }
  .blog-content {
    font-size: 16px;
  }
  .blog-content img {
    width: 100%;
  }
  .sidebar-parent {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
  .apply-now-on-loan {
    position: relative;
    width: 100%;
  }
  .apply-now-on-loan img {
    width: 100%;
  }
  .apply-now-on-loan-text {
    position: absolute;
    bottom: 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    height: 100%;
    padding: 45px;
  }
  .apply-now-on-loan-text h2 {
    text-align: center;
    color: #fff;
  }
  .button-name {
    height: 54px;
    padding: 25px;
    border: 2px solid green;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.85), rgb(4, 179, 95));
  }
`;
const formatTitleForLink = (title) => {
  return title
    .replace(/[^a-zA-Z0-9\s]/g, "")
    .replace(/\s+/g, "-")
    .toLowerCase();
};

export default function BlogPostDetail() {
  const { title } = useParams();
  const [blogPost, setBlogPost] = useState(null);

  useEffect(() => {
    const post = blogPosts.find(
      (post) => post.url.toLowerCase() === title.toLowerCase()
    );
    setBlogPost(post);
  }, [title]);

  useEffect(() => {
    if (blogPost) {
      const tocItems = document.querySelectorAll(".blog-content h2");
      const tocContainer = document.querySelector(".table-of-content-cards");
      tocContainer.innerHTML = "";
      tocItems.forEach((item) => {
        const tocLink = document.createElement("div");
        tocLink.className = "content-label-text";
        tocLink.innerHTML = `<a href="#${item.id}">${item.innerText}</a>`;
        tocContainer.appendChild(tocLink);
      });

      const links = tocContainer.querySelectorAll("a");
      links.forEach((link) => {
        link.addEventListener("click", (event) => {
          event.preventDefault();
          const targetId = link.getAttribute("href").substring(1);
          const targetElement = document.getElementById(targetId);
          window.scrollTo({
            top: targetElement.offsetTop - 50,
            behavior: "smooth",
          });
        });
      });
    }
  }, [blogPost]);

  const renderCategoryCards = () => {
    const categories = ["Personal Loan", "Business Loan"];

    return categories.map((category) => (
      <Link
        key={category}
        to={`/blogs/category/${formatTitleForLink(category)}`}
        className={`card-label ${blogPost?.type === category ? "active" : ""}`}
      >
        {category}
      </Link>
    ));
  };

  const renderApplyNowSection = () => {
    return (
      <div className="sidebar">
        <div className="apply-now-on-loan">
          <img src={PersonalLoanImage} alt="Personal Loan" />
          <div className="apply-now-on-loan-text">
            <h2>
              Get your <strong>{blogPost?.type}</strong> now!
            </h2>
            <Link
              to={
                blogPost?.type === "Personal Loan"
                  ? "/personal-loan"
                  : "/business-loan"
              }
              className="button-name"
            >
              For {blogPost?.type}
            </Link>
          </div>
        </div>
      </div>
    );
  };

  const renderPopularBlogs = () => {
    return blogPosts
      .filter((post) => post.url !== blogPost?.url)
      .map((post) => (
        <div key={post.url} className="content-label-text">
          <Link to={`/blogs/${post.url}`}>{post.title}</Link>
        </div>
      ));
  };

  if (!blogPost) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Helmet>
        <title>{blogPost?.title}</title>
        <meta name="title" content={blogPost?.title} />
        <meta name="description" content={blogPost?.metaDescription} />
        <link
          rel="canonical"
          href={`https://www.finlab.one/${blogPost?.url}`}
        />
      </Helmet>
      <Header />
      <HeroSection
        className="header-image ken-burn-center light"
        data-parallax="true"
        data-natural-height="500"
        data-natural-width="1920"
        data-bleed="0"
        data-image-src={blogPost?.image}
        data-offset="0"
        backgroundImage={blogPost?.image}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <HeroTitle>{blogPost.title}</HeroTitle>
              <HeroSubtitle></HeroSubtitle>
            </div>
          </div>
        </div>
      </HeroSection>
      <StyledDiv className="section">
        <div className="main-container">
          <div className="row">
            <div className="col-md-3">
              <div className="sidebar table-of-content">
                <div className="category">
                  <h2>Table of Contents</h2>
                </div>
                <div className="table-of-content-cards">
                  {/* Dynamic content will be inserted here */}
                </div>
              </div>
            </div>
            <div className="col-md-6" style={{ textAlign: "justify" }}>
              <div className="category">
                <h2>{blogPost.title}</h2>
                <div className="actegory-label">
                  <div>By: {blogPost.author}</div>
                  <div className="divider"></div>
                  <div>{blogPost.date}</div>
                  <div className="bullet"></div>
                  <div>{blogPost.readTime} read</div>
                </div>
              </div>
              <div
                className="blog-content"
                dangerouslySetInnerHTML={{ __html: blogPost.content }}
              ></div>
              {blogPost.showApplyBtn && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Link
                    className="card-label"
                    style={{ width: "250px", cursor: "pointer" }}
                    to={blogPost.applyBtnLink}
                  >
                    Apply Now
                  </Link>
                </div>
              )}
            </div>
            <div className="col-md-3">
              <div className="sidebar-parent">
                <div className="sidebar">
                  <div className="category">
                    <h2>Category</h2>
                  </div>
                  <div className="category-cards">{renderCategoryCards()}</div>
                </div>
                {renderApplyNowSection()}
                <div className="sidebar">
                  <div className="category">
                    <h2>Popular Blogs for you</h2>
                  </div>
                  <div className="table-of-content-cards">
                    {renderPopularBlogs()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledDiv>
      <Footer className={"footer_section custom_footer_style"} />
    </>
  );
}
