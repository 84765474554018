import AdminHeader from "../../layouts/admin-header";
import AdminFooter from "../../layouts/admin-footer";
import AdminNavBar from "../../layouts/admin-nav-bar";
import { useState, useEffect } from "react";
import Loader from "../../loader";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { API_URL } from "../../../config/constant";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDropzone } from "react-dropzone";
import htmlEditButton from "quill-html-edit-button"; // Import the HTML edit button module

// Register the module with Quill
ReactQuill.Quill.register("modules/htmlEditButton", htmlEditButton);

export default function AddBlog({ menuAccess }) {
  const [loader, setLoader] = useState(false);
  const [showApplyButton, setShowApplyButton] = useState(false);
  const [exposeUrlField, setExposeUrlField] = useState(false);
  const [description, setDescription] = useState("");

  const [error, setError] = useState("");
  const [preview, setPreview] = useState(null);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    multiple: false,
    onDrop: (acceptedFiles) => {
      setError(""); // Reset error state
      setLoader(true); // Show loader
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        const img = new Image();

        img.onload = () => {
          setLoader(false); // Hide loader
          if (img.width >= 720 && img.height >= 1080) {
            setPreview(URL.createObjectURL(file)); // Set preview if valid
          } else {
            setError("Image resolution must be at least 720×1080 pixels.");
          }
        };

        img.src = URL.createObjectURL(file);
      }
    },
  });

  const toolbarOptions = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["bold", "italic", "underline"],
    [{ align: [] }],
    ["link", "image"],
    ["clean"], // Add code block option for source code view
    ["html"], // Add HTML view option
  ];

  const modules = {
    toolbar: toolbarOptions,
    htmlEditButton: { debug: true }, // Enable HTML edit button with debug mode
  };

  // Function to remove the uploaded image
  const handleRemove = () => {
    setPreview(null);
    setError("");
  };
  const handleBlogDescription = (value) => {
    console.log(value);
    setDescription(value);
  };
  return (
    <>
      <ToastContainer />
      {loader && <Loader />}
      <div className="layout-wrapper">
        <div className="layout-container">
          <AdminNavBar menuAccess={menuAccess} />
          <div className="adminMain-wrapper">
            <AdminHeader />
            <div className="mainContent">
              <div className="topHeadings mb-4">
                <h3>Add Blog</h3>
              </div>
              <div className="contentBlocks" style={{ marginBottom: "20px" }}>
                <div className="sectionTable">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-xs-12">
                        <select className="form-control">
                          <option>Category</option>
                        </select>
                      </div>

                      <div className="col-lg-6 col-md-6 col-xs-12">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Blog read time (mins)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="contentBlocks" style={{ marginBottom: "20px" }}>
                <div className="sectionTable">
                  <div className="container-fluid">
                    <div className="row">
                      <div
                        className="col-lg-12 col-md-12 col-xs-12"
                        style={{ minHeight: "200px" }}
                      >
                        {preview ? (
                          <div
                            style={{ textAlign: "center", marginTop: "10px" }}
                          >
                            <img
                              src={preview}
                              alt="Preview"
                              style={{
                                width: "100%",
                                borderRadius: "5px",
                                maxHeight: "200px",
                                objectFit: "cover",
                              }}
                            />
                            <button
                              onClick={handleRemove}
                              className="blog-image-remove"
                            >
                              ☓
                            </button>
                          </div>
                        ) : (
                          <div
                            {...getRootProps()}
                            className="form-control text-center p-3 border border-dashed"
                            style={{ cursor: "pointer", minHeight: "200px" }}
                          >
                            <input {...getInputProps()} />
                            <p style={{ marginTop: "-10px" }}>
                              Drag & drop an image here, or click to select with
                              minimum dimensions of 720×1080 pixels.
                            </p>
                          </div>
                        )}

                        {error && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "14px",
                              marginTop: "0px",
                              paddingLeft: "35%",
                            }}
                          >
                            {error}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="contentBlocks" style={{ marginBottom: "20px" }}>
                <div className="sectionTable">
                  <div className="container-fluid">
                    <div className="row">
                      <div
                        className="col-lg-12"
                        style={{ marginBottom: "20px" }}
                      >
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Blog Title"
                        />
                      </div>
                      <div className="col-lg-12">
                        <ReactQuill
                          onChange={handleBlogDescription}
                          placeholder="Blog description"
                          theme="snow"
                          modules={modules}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="contentBlocks" style={{ marginBottom: "20px" }}>
                <div className="sectionTable">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-6">
                        <input
                          type="checkbox"
                          onChange={() => setShowApplyButton(!showApplyButton)}
                        />{" "}
                        Show Apply Now Button
                        {showApplyButton && (
                          <button
                            className="btn btn-primary"
                            style={{
                              height: "36px",
                              marginLeft: "10px",
                              padding: ".37rem .75rem",
                            }}
                          >
                            Apply Now
                          </button>
                        )}
                      </div>
                      <div className="col-lg-6">
                        <input
                          type="checkbox"
                          onChange={() => setExposeUrlField(!exposeUrlField)}
                        />{" "}
                        Expose input field for URL
                        {exposeUrlField && (
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter URL"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="contentBlocks" style={{ marginBottom: "20px" }}>
                <div className="sectionTable">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-6">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Meta tag title"
                        />
                        <div className="col-lg-6 col-md-6 col-xs-6">
                          <div
                            className="form-group"
                            style={{
                              display: "Flex",
                              justifyContent: "flex-start",
                              marginTop: "20px",
                            }}
                          >
                            <button type="reset" className="btn btn-secondary">
                              Reset
                            </button>
                            &nbsp;
                            <button type="button" className="btn btn-primary">
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <ReactQuill
                          value={description}
                          onChange={setDescription}
                          placeholder="Meta blog description"
                          theme="snow" // Snow theme provides a toolbar
                          modules={modules}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <AdminFooter />
          </div>
        </div>
      </div>
    </>
  );
}
